@include fontFace('fa-brands', 400);

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto:100,300,300i,400,400i,500,700');

$font-1: 'Roboto', sans-serif;
$font-2: 'Roboto Condensed', sans-serif;

$xs: 'all and (max-width: 374px)';
$mobile: 'all and (max-width: 767px)';
$lg: 'all and (min-width: 768px)';
$tablet: 'all and (min-width: 768px) and (max-width: 1024px)';

$blue: #008cc4;
$blue-2: #0094cf;

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  color: #000;
  font-size: 18px;
  line-height: 1.25;
  font-family: $font-1;
  &.modal-open {
    overflow: hidden;
  }
}

body,
html {
  position: relative;
  height: 100%;
}

.container {
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
  padding: 0 15px;
  @media #{$mobile} {
    padding: 0 10px;
  }
}

a {
  color: inherit;
  text-decoration: none;
  outline: 0;
  transition-duration: .3s;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h2,
h3,
h4 {
  margin-top: 0;
}

p {
  margin: 0 0 24px;
  &:last-child {
    margin: 0;
  }
}

.hide-lg {
  @media #{$lg} {
    display: none !important;
  }
}

.hide-sm {
  @media #{$mobile} {
    display: none !important;
  }
}

.btn,
a.cc-btn {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 228px;
  margin: 0 auto;
  padding: 0;
  border: 0;
  outline: 0;
  border-radius: 50px;
  z-index: 1;
  background: $blue;
  color: #fff;
  font: 23px/64px $font-2;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: $blue-2;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  @media (min-width: 1025px) {
    &:hover {
      span {
        width: 225%;
        height: 562.5px;
      }
    }
    &:active {
      transition: all 150ms linear;
      opacity: .75;
    }
  }
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}