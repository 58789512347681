#footer {
  background: #eee9df;
  @media #{$lg} {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  @media #{$mobile} {
    margin-top: 15px;
    padding: 16px 0 20px;
    text-align: center;
  }
  .container {
    @media #{$lg} {
      max-width: 970px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 77px;
    }
  }
}

.website {
  @media #{$mobile} {
    margin-bottom: 24px;
  }
  a {
    color: #807d78;
    font-size: 12px;
    @media #{$mobile} {
      font-size: 14px;
    }
    &:hover {
      color: #000;
    }
  }
}
.social-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$mobile} {
    max-width: 268px;
    margin: 0 auto 28px;
  }
  li {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    display: block;
    width: 27px;
    height: 27px;
    border: 1px solid #1d1c1b;
    border-radius: 50%;
    text-align: center;
    @media #{$mobile} {
      width: 62px;
      height: 62px;
      border-width: 2px;
    }
    &:before {
      font: 15px/25px 'fa-brands';
      color: #1d1c1b;
      @media #{$mobile} {
        font-size: 32px;
        line-height: 58px;
      }
    }
    &.facebook:before {
      content: '\f39e';
    }
    &.twitter:before {
      content: '\f099';
      font-size: 13px;
      @media #{$mobile} {
        font-size: 29px;
      }
    }
    &.email {
      background: url(../img/mail-f.png) center no-repeat;
      @media #{$lg} {
        background-size: 13px auto;
        &:hover {
          background-image: url(../img/mail-f-w.png);
        }
      }
    }
    &:hover {
      @media #{$lg} {
        background-color: #1d1c1b;
        &:before {
          color: #fff;
        }
      }
    }
  }
}

.footer-menu {
  ul {
    @media #{$lg} {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  li {
    margin-left: 60px;
    @media #{$mobile} {
      margin: 0 0 11px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  a {
    color: #807d78;
    font-size: 12px;
    @media #{$mobile} {
      font-size: 14px;
    }
    &:hover {
      color: #000;
    }
  }
}