#header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 30px;
  text-align: center;
  @media #{$mobile} {
    padding-top: 12px;
  }
  .logo {
    display: inline-block;
    @media #{$mobile} {
      width: 150px;
    }
  }
}