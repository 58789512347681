main {
  position: relative;
  min-height: 100%;
  @media #{$lg} {
    height: 100vh;
    min-height: 650px;
    overflow: hidden;
  }
}

/*** Intro ***/

h1 {
  margin: 22px 0 40px;
  font-size: 42px;
  text-transform: uppercase;
  @media #{$mobile} {
    margin: 14px 0 56px;
    font-size: 31px;
  }
  @media (max-width: 767px) and (max-height: 580px) {
    margin-bottom: 30px;
    font-size: 28px;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    margin-bottom: 20px;
    font-size: 25px;
  }
  @media (min-width: 768px) and (max-height: 740px) {
    margin: 15px 0 25px;
    font-size: 35px;
  }
}

.block-section {
  position: relative;
  @media #{$lg} {
    position: absolute;
    display: flex;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
  }
  @media #{$mobile} {
    display: none;
  }
  &.active {
    @media #{$lg} {
      bottom: 0;
      z-index: 100;
      transition: bottom .7s;
      animation-timing-function: ease-out;
    }
    @media #{$mobile} {
      display: flex;
    }
  }
  &.out {
    @media #{$lg} {
      bottom: 100%;
      transition: bottom .7s;
      animation-timing-function: ease-out;
    }
  }
}

.block-section,
.slide {
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    width: 4px;
    height: 101px;
    margin-left: -2px;
    background: url(../img/dot.png);
    @media #{$mobile} {
      width: 3px;
      height: 25px;
      background-size: 100% auto;
    }
  }
  &:before {
    bottom: 0;
  }
  &:after {
    top: 0;
    background-position: 0 bottom;
    @media #{$mobile} {
      height: 77px;
    }
  }
}

.block-intro,
.block-desinscription {
  background: url(../img/bg-intro.jpg) center no-repeat;
  background-size: cover;
  @media (min-width: 768px) and (max-height: 800px) {
    background-position: center top;
  }
  @media #{$mobile} {
    background-image: url(../img/bg-intro-sm.jpg);
    background-position: center top;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    background: url(../img/bg-intro.jpg) center no-repeat;
    background-size: cover;
  }
  &:after {
    @media #{$lg} {
      content: none;
    }
    @media #{$mobile} {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../img/intro-shadow.png) center bottom no-repeat;
      background-size: cover;
      margin: 0;
    }
  }
}

.block-intro {
  padding-bottom: 15.5vh;
  text-align: center;
  color: #fff;
  @media (min-width: 768px) and (max-height: 740px) {
    padding-bottom: 50px;
  }
  @media #{$mobile} {
    min-height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 49px;
    z-index: 0;
  }
  @media (max-width: 767px) and (max-height: 580px) {
    padding-bottom: 40px;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    min-height: 0;
    padding-bottom: 20px;
  }
  .container {
    max-width: 100%;
    align-self: flex-end;
    position: relative;
    z-index: 1;
  }
  .subtitle {
    font-size: 34px;
    line-height: 1.2;
    @media #{$mobile} {
      font-size: 25px;
    }
    @media (max-width: 767px) and (max-height: 580px) {
      font-size: 23px;
    }
    @media (max-width: 767px) and (orientation: landscape) {
      font-size: 20px;
    }
    @media (min-width: 768px) and (max-height: 740px) {
      font-size: 28px;
    }
  }
  br {
    @media (max-width: 767px) and (orientation: landscape) {
      display: none !important;
    }
  }
}

/*** Identification ***/

.block-identification {
  background: url(../img/bg-identification.jpg) center no-repeat;
  background-size: cover;
  @media #{$mobile} {
    padding: 54vw 0 15px;
    background: url(../img/bg-identification-sm.jpg) center top no-repeat #ac6337;
    background-size: 100% auto;
  }
  .radios {
    @media #{$lg} {
      display: flex;
    }
  }
}

.radio,
.checkbox {
  label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border: 1px solid #000;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    &:checked + label:before {
      background: $blue;
      border: 0;
    }
  }
  &:first-child {
    margin-right: 21px;
  }
}

.form-item > label.error {
  color: red;
  opacity: .5;
}

.error-container,
.inscription-error {
  display: none !important;
}

.block-form {
  background: url(../img/form-bg.png);
  padding: 28px 15px 24px;
  position: relative;
  @media #{$mobile} {
    padding: 25px 10px 51px;
  }
  > div {
    max-width: 745px;
    margin: 0 auto;
  }
  .subtitle {
    text-align: center;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 20px;
    @media #{$mobile} {
      margin-bottom: 11px;
      font-size: 16px;
    }
  }
  h2 {
    margin: 0 0 21px;
    font: bold 26px/31px $font-2;
    color: $blue;
    text-align: center;
    @media #{$mobile} {
      margin-bottom: 33px;
      font-size: 22px;
      line-height: 26px;
    }
  }
  .form-item {
    margin-bottom: 21px;
    @media #{$mobile} {
      margin-bottom: 9px;
    }
    & > label {
      float: left;
      padding-right: 15px;
      font-weight: bold;
      em {
        font-weight: 300;
      }
      @media #{$mobile} {
        float: none;
        display: block;
        margin: 0 0 4px;
      }
    }
    &--accept {
      overflow: hidden;
      @media #{$mobile} {
        margin: 13px 0 18px;
      }
      label {
        &:before,
        &:after {
          transform: translateY(0);
        }
        &:before {
          top: 2px;
        }
        &:after {
          top: 5px;
        }
      }
    }
    &--prenom,
    &--nom,
    &--email,
    &--phone,
    &--date {
      @media #{$lg} {
        display: flex;
      }
      .form-control {
        width: 100%;
      }
      label {
        @media #{$lg} {
          flex: 0 0 auto;
          white-space: nowrap;
        }
      }
    }
    &--prenom {
      @media #{$lg} {
        float: left;
        width: 50%;
        max-width: 373px;
      }
    }
    &--nom {
      @media #{$lg} {
        display: flex;
        float: right;
        width: 50%;
        max-width: 345px;
      }
    }
    &--email {
      @media #{$lg} {
        display: flex;
        position: relative;
        float: left;
        width: 50%;
        max-width: 373px;
        .form-control {
          padding-right: 115px;
        }
      }
    }
    &--phone {
      @media #{$lg} {
        display: flex;
        position: relative;
        float: right;
        width: 50%;
        max-width: 345px;
        .form-control {
          padding-right: 65px;
        }
      }
    }
    &--date {
      clear: both;
      .form-control {
        @media #{$lg} {
          max-width: 103px;
        }
      }
      label {
        flex: none;
        @media #{$xs} {
          font-size: 15px;
        }
      }
    }
    &--submit {
      text-align: center;
      margin-top: 35px;
      margin-bottom: 9px;
      @media #{$mobile} {
        margin-top: 25px;
        margin-bottom: 22px;
      }
    }
    input[type=date] {
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        -webkit-appearance: none;
        display: none;
      }
    }
  }
  .description {
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
    opacity: .6;
    @media #{$mobile} {
      font-size: 13px;
    }
  }
  .obligatoire {
    font-weight: 300;
    font-style: italic;
    font-size: 12px;
    position: absolute;
    bottom: 15px;
    right: 25px;
    opacity: .6;
    @media #{$mobile} {
      font-size: 14px;
    }
    &.active {
      font-weight: bold;
      color: red;
      opacity: .5;
    }
  }
}

.birthay-error,
.email-error,.phone-error {
  display: none;
  align-items: flex-end;
  color: red;
  opacity: .5;
  margin-left: 24px;
  font-style: italic;
  font-size: 12px;
}

.email-error,.phone-error {
  position: absolute;
  top: 3px;
  right: 0;
}

.form-control.error,.is-error  {
  + .birthay-error,
  + .email-error,
  + .phone-error{
    @media #{$lg} {
      display: flex;
    }
  }
  &.empty + .email-error {
    display: none;
  }
}

.form-control {
  height: 22px;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: 0;
  outline: 0;
  border-bottom: 1px solid #000;
  font-weight: 100;
  font-style: italic;
  font-size: 16px;
  font-family: inherit;
}

.question-title {
  margin-bottom: 26px;
  font: bold 28px/33px $font-2;
  @media #{$mobile} {
    min-height: 92px;
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 26px;
  }
}

.form-item--civility {
  position: relative;
  z-index: 2;
  .radios {
    @media #{$mobile} {
      position: relative;
      height: 55px;
      padding-right: 54px;
      display: flex;
      align-items: center;
      border: 1px solid #858585;
      .radio {
        margin: 0;
        + .radio {
          label {
            top: 108px;
            border-top: 1px solid #858585;
          }
        }
      }
      .arrow {
        position: absolute;
        top: 0;
        right: 0;
        width: 54px;
        height: 55px;
        cursor: pointer;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 0;
          height: 0;
          margin: auto;
          border-style: solid;
          border-width: 10px 8px 0 8px;
          border-color: #000 transparent transparent transparent;
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 41px;
          background: #858585;
        }
      }
      label {
        display: none;
        position: absolute;
        top: 54px;
        left: -1px;
        right: -1px;
        height: 55px;
        padding: 0 15px;
        border: 1px solid #858585;
        border-top: 0;
        background: #fff;
        line-height: 55px;
        white-space: nowrap;
        &:before {
          content: none;
        }
      }
      input:checked + label {
        display: block !important;
        position: static;
        border: 0;
        background: 0;
      }
    }
  }
  .radio label {
    @media #{$lg} {
      display: inline-block !important;
    }
  }
}

.form-item--civility.selected .radios .radio+.radio label {
  top: 54px;
  border-top: 0;
}

.form-item-text {
  @media #{$mobile} {
    position: relative;
    z-index: 1;
    height: 55px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    border: 1px solid #858585;
    &.focused label {
      display: none;
    }
    label,
    .form-control {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 15px;
      line-height: 55px;
    }
    label {
      span {
        display: none;
      }
    }
    .form-control {
      border: 0;
    }
  }
  .form-control.error {
    color: rgba(255, 0, 0, 0.5)
  }
}

/*** Quiz ***/

.block-quiz {
  @media #{$mobile} {
    height: 645px;
    min-height: 100vh;
    overflow: hidden;
  }
  &:before,
  &:after {
    content: none;
  }
  .answers {
    @media #{$mobile} {
      padding: 0 30px;
    }
  }
  .radio {
    margin-bottom: 15px;
    @media #{$mobile} {
      margin: 49px 0 0;
    }
    label {
      padding-left: 46px;
      @media #{$mobile} {
        padding-left: 0;
        font-weight: bold;
      }
      &:before {
        width: 26px;
        height: 25px;
        border: 0;
        background: url(../img/radio.png) 0 0 no-repeat;
        @media #{$mobile} {
          content: none;
        }
      }
    }
  }
  input:checked+label {
    font-weight: bold;
    &:before {
      background-image: url(../img/radio-checked.png);
      background-color: transparent;
      @media #{$mobile} {
        content: none;
      }
    }
  }
  .btn {
    margin-top: 22px;
  }
  .btn-submit {
    display: none;
  }
  .slide:last-child {
    .btn-submit {
      display: block;
    }
    .btn-next {
      display: none;
    }
  }
  .error {
    display: none;
    margin-top: 11px;
    color: red;
    opacity: .5;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
}

.question-wrapper {
  position: relative;
}

.slide {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  @media #{$mobile} {
    display: block;
    height: 1000px;
    padding: 157px 15px 17px;
    text-align: center;
  }
  &.active-slide {
    right: 0;
  }
  & + .active-slide {
    transition: 1s;
    animation-timing-function: ease-out;
  }
  &.out {
    right: 100%;
    transition: 1s;
    animation-timing-function: ease-out;
  }
  &.hidden {
    display: none;
  }
  .block-form {
    width: 890px;
    max-width: 100%;
    height: 520px;
    padding: 59px 15px;
    overflow: hidden;
    margin: 0 auto;
    @media #{$mobile} {
      height: 463px;
    }
  }
}

.slide:first-child {
  background: url(../img/bg-quiz.jpg) center top no-repeat;
  background-size: cover;
  &:before {
    top: 120vw;
    left: 0;
    width: 124%;
    height: 100%;
    box-shadow: -46px -51px 54px 0 #d2b894;
    background: #d2b894;
    margin: 0;
  }
  @media #{$mobile} {
    background: url(../img/bg-quiz-sm.jpg) center top no-repeat #d2b894;
    background-size: 100% auto;
  }
}

.slide:nth-child(2) {
  background: url(../img/bg-quiz-2.jpg) center top no-repeat;
  background-size: cover;
  &:after,
  &:before {
    content: none;
  }
  @media #{$mobile} {
    background: url(../img/bg-quiz-sm-2.jpg) center top no-repeat #a76f45;
    background-size: 100% auto;
  }
}

.slide:nth-child(3) {
  background: url(../img/bg-quiz-3.jpg) center top no-repeat;
  background-size: cover;
  &:after,
  &:before {
    content: none;
  }
  @media #{$mobile} {
    background: url(../img/bg-quiz-sm-3.jpg) center top no-repeat #7a883c;
    background-size: 100% auto;
  }
}

.slide:nth-child(4) {
  background: url(../img/bg-quiz-4.jpg) center top no-repeat;
  background-size: cover;
  &:after {
    content: none;
  }
  @media #{$mobile} {
    background: url(../img/bg-quiz-sm-4.jpg) center top no-repeat #a76f45;
    background-size: 100% auto;
  }
}

.slide-inner {
  max-width: 745px;
  margin: 0 auto;
}

.pager {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-weight: 100;
  font-size: 40px;
  @media #{$mobile} {
    bottom: auto;
    right: auto;
    top: 21px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 33px;
  }
  strong {
    font-size: 24.8px;
    @media #{$mobile} {
      font-size: 20px;
    }
  }
}

.owl-dots {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.owl-dot {
  display: inline-block;
  vertical-align: middle;
  width: 17px;
  height: 17px;
  margin: 0 5px;
  background: url(../img/radio.png) 0 0 no-repeat;
  background-size: 100% auto;
  pointer-events: none;
  cursor: default;
  &.active {
    width: 26px;
    height: 25px;
    background-image: url(../img/radio-checked.png);
  }
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 55px;
  width: 30px;
  height: 65px;
  text-indent: -9999px;
  overflow: hidden;
  &.disabled {
    display: none;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 35px;
    height: 3px;
    background: #7b7b7b;
  }
}

.owl-prev {
  left: -14px;
  &:before {
    top: 44px;
    transform: rotate(60deg);
  }
  &:after {
    top: 15px;
    transform: rotate(-60deg);
  }
}

.owl-next {
  right: -14px;
  &:before,
  &:after {
    left: auto;
    right: 0;
  }
  &:before {
    top: 15px;
    transform: rotate(60deg);
  }
  &:after {
    top: 44px;
    transform: rotate(-60deg);
  }
}

/*** Result ***/

.block-result {
  padding-bottom: 77px;
  background: url(../img/bg-result.jpg) center no-repeat;
  background-size: cover;
  @media #{$mobile} {
    flex-direction: column;
    padding: 53vw 0 0;
    background: url(../img/bg-result-sm.jpg) center top no-repeat #bb8857;
    background-size: 100% auto;
  }
  &:before {
    content: none;
  }
}

.form-result {
  padding: 34px 15px;
  @media #{$mobile} {
    padding-bottom: 59px;
  }
  .name {
    font-weight: 300;
    font-size: 26px;
    text-align: center;
    @media #{$mobile} {
      font-size: 22px;
    }
  }
  h2 {
    margin-bottom: 28px;
    text-transform: uppercase;
    @media #{$mobile} {
      margin-bottom: 36px;
    }
  }
}

.result-links {
  .block-title {
    margin: 12px 0 14px;
    color: $blue;
    font-weight: bold;
    font-size: 18px;
    @media #{$mobile} {
      margin: 19px 0 27px;
    }
  }
  .blocks {
    display: none;
    @media #{$lg} {
      margin: 0 -8px;
    }
  }
  .block {
    @media #{$lg} {
      width: 25%;
      padding: 0 8px;
    }
    @media #{$mobile} {
      margin-bottom: 60px;
      &:last-child {
        margin: 0;
      }
    }
  }
  .inner {
    position: relative;
    height: 100%;
    min-height: 103px;
    padding: 8px 10px 22px;
    border-radius: 18px;
    border: 1px solid $blue-2;
    text-align: center;
    @media #{$mobile} {
      min-height: 82px;
    }
    &.no-pad {
      @media #{$lg} {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    br {
      @media #{$mobile} {
        display: none;
      }
    }
  }
  .links {
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    @media #{$mobile} {
      bottom: -21px;
    }
  }
  .button {
    display: inline-block;
    position: relative;
    width: 135px;
    border-radius: 25px;
    border: 1px solid $blue-2;
    background: #fff;
    color: $blue;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
    @media #{$mobile} {
      width: 176px;
      font-size: 18px;
      line-height: 42px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 10px;
      top: 0;
      width: 30px;
      height: 100%;
      background-position: 0 center;
      background-repeat: no-repeat;
    }
    &:hover {
      @media #{$lg} {
        background: $blue;
        color: #fff;
      }
    }
    &:active {
      transition: all 150ms linear;
      opacity: .75;
    }
    &.sign {
      padding-left: 49px;
      @media #{$mobile} {
        padding-left: 69px;
      }
      &:before {
        left: 13px;
        background-image: url(../img/petition.png);
        @media #{$mobile} {
          left: 24px;
        }
      }
      &:hover:before {
        @media #{$lg} {
          background-image: url(../img/petition-w.png);
        }
      }
    }
    &.discover {
      padding-left: 36px;
      @media #{$mobile} {
        padding-left: 55px;
      }
      &:before {
        background-image: url(../img/panier.png);
        @media #{$mobile} {
          left: 23px;
        }
      }
      &:hover:before {
        @media #{$lg} {
          background-image: url(../img/panier-w.png);
        }
      }
    }
    &.discover-handshake {
      padding-left: 36px;
      @media #{$mobile} {
        padding-left: 55px;
      }
      &:before {
        left: 7px;
        background-image: url(../img/handshake.png);
        @media #{$mobile} {
          left: 21px;
        }
      }
      &:hover:before {
        @media #{$lg} {
          background-image: url(../img/handshake-w.png);
        }
      }
    }
    &.support {
      padding-left: 39px;
      @media #{$mobile} {
        padding-left: 59px;
      }
      &:before {
        background-image: url(../img/soutien.png);
        @media #{$mobile} {
          left: 20px;
        }
      }
      &:hover:before {
        @media #{$lg} {
          background-image: url(../img/soutien-w.png);
        }
      }
    }
    &.abonne {
      padding-left: 34px;
      @media #{$mobile} {
        padding-left: 50px;
      }
      &:before {
        left: 10px;
        background-image: url(../img/abonne.png);
        @media #{$mobile} {
          left: 20px;
        }
      }
      &:hover:before {
        @media #{$lg} {
          background-image: url(../img/abonne-w.png);
        }
      }
    }
    &.discover-ecologist {
      padding-left: 35px;
      @media #{$mobile} {
        padding-left: 50px;
      }
      &:before {
        left: 13px;
        background-image: url(../img/leaves.png);
        @media #{$mobile} {
          left: 20px;
        }
      }
      &:hover:before {
        @media #{$lg} {
          background-image: url(../img/leaves-w.png);
        }
      }
    }
  }
  .share {
    display: flex;
    justify-content: center;
    a {
      display: block;
      width: 34px;
      height: 34px;
      margin: 0 8px;
      border-radius: 50%;
      border: 1px solid $blue-2;
      background: #fff;
      color: $blue-2;
      @media #{$mobile} {
        width: 48px;
        height: 48px;
        margin: 0 12px;
      }
      &:before {
        font: 18px/32px 'fa-brands';
        @media #{$mobile} {
          font-size: 24px;
          line-height: 46px;
        }
      }
      &:hover {
        @media #{$lg} {
          background: $blue;
          color: #fff;
        }
      }
      &:active {
        transition: all 150ms linear;
        opacity: .75;
      }
    }
    .facebook:before {
      content: '\f39e';
    }
    .twitter:before {
      content: '\f099';
      font-size: 15px;
      @media #{$mobile} {
        font-size: 22px;
      }
    }
    .email {
      background: url(../img/mail.png) center no-repeat #fff;
      @media #{$lg} {
        background-size: 16px auto;
      }
      &:hover {
        background: url(../img/mail-w.png) center no-repeat $blue;
      }
    }
  }
}

.block-c .block {
  &:first-child {
    @media #{$lg} {
      width: 23%;
    }
  }
  &:nth-child(2) {
    @media #{$lg} {
      width: 28%;
    }
  }
  &:nth-child(3) {
    @media #{$lg} {
      width: 24%;
    }
  }
  &:nth-child(4) {
    @media #{$lg} {
      width: 25%;
    }
  }
}

.block-e .block {
  &:nth-child(4) {
    @media #{$lg} {
      width: 27%;
    }
  }
}

.profile-0,
.profile-1 {
  .block-a {
    display: block;
    @media #{$lg} {
      display: flex;
    }
  }
}

.profile-2,
.profile-3 {
  .block-b {
    display: block;
    @media #{$lg} {
      display: flex;
    }
  }
}

.profile-4 {
  .block-c {
    display: block;
    @media #{$lg} {
      display: flex;
    }
  }
}

.profile-5,
.profile-6 {
  .block-d {
    display: block;
    @media #{$lg} {
      display: flex;
    }
  }
}

.profile-7,
.profile-8 {
  .block-e {
    display: block;
    @media #{$lg} {
      display: flex;
    }
  }
}

//.form-inscription {
//  margin-top: 10px;
//  padding: 20px 186px 20px 15px;
//  background-image: url(../img/form-bg-2.png);
//  @media #{$mobile} {
//    margin-top: 19px;
//    padding: 25px 10px;
//  }
//  >div {
//    max-width: 100%;
//  }
//  .form-item {
//    margin: 0;
//  }
//  .btn-submit {
//    background: #00558c;
//    @media #{$lg} {
//      position: absolute;
//      top: 50%;
//      right: 18px;
//      width: 140px;
//      margin-top: -18px;
//      padding: 0;
//      font-size: 19px;
//      line-height: 37px;
//    }
//  }
//  .form-control {
//    @media #{$mobile} {
//      width: 100%;
//      height: 53px;
//      margin: 6px 0 25px;
//      padding: 0 15px;
//      border: 1px solid #858585;
//    }
//  }
//}
//
//.inscription-success {
//  display: none;
//  color: green;
//  opacity: .7;
//  font-weight: 600;
//  font-size: 16px;
//  text-align: right;
//  margin-top: 15px;
//  @media #{$mobile} {
//    text-align: center;
//  }
//}
//
//.form-item--inscription {
//  display: flex;
//  justify-content: space-between;
//  align-items: flex-end;
//  @media #{$mobile} {
//    display: block;
//  }
//  label {
//    max-width: 430px;
//    font-size: 16px;
//    @media #{$mobile} {
//      max-width: 100%;
//    }
//  }
//}

/*** Popup ***/
.modal {
  display: none;
  position: fixed;
  top: 0;
  padding: 3%;
  z-index: 9999;
  width: 100%;
  height: 100%;
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 30px;
    height: 30px;
    z-index: 1;
    cursor: pointer;
    transition-duration: .2s;
    font-size: 0;
    color: transparent;
    outline: 0;
    @media #{$mobile} {
      top: 6px;
      right: 8px;
      transform: scale(0.9);
    }
    &:hover {
      opacity: .6;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 3px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      border-radius: 5px;
      background: #000;
      @media #{$mobile} {
        height: 2px;
      }
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: rgba(0,0,0,.8);
  cursor: pointer;
}

.modal-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 890px;
  max-width: 95%;
  height: 520px;
  margin: auto;
  padding: 70px 20px 45px 43px;
  background: #fff;
  @media (max-height: 550px) {
    height: 420px;
  }
}

.modal-content {
  height: 405px;
  overflow-y: auto;
  outline: 0;
  @media (max-height: 550px) {
    height: 305px;
  }
}

.popup-container {
  .container {
    padding: 0 13px 0 0;
    font-size: 16px;
  }
  h1 {
    margin: 0 0 20px;
    font-size: 25px;
    text-align: center;
  }
  h2 {
    margin-bottom: 5px;
    font-size: 16px;
    text-transform: uppercase;
  }
  p {
    margin: 0 0 18px;
    &:last-child {
      margin: 0;
    }
  }
}

/*** Cookies ***/
.cc-banner.cc-top,
.cc-window {
  justify-content: center;
  align-items: center;
  padding: 11px 15px;
  .btn {
    margin-left: 22px;
    margin-right: 10px;
  }
  .btn,
  .cc-btn,
  .cc-link {
    display: inline-block;
    vertical-align: middle;
    width: 117px;
    padding: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 33px;
    background: $blue !important;
    span {
      background: #0094cf;
    }
  }
  .btn {
    background: #9a9a9a !important;
  }
}


.cc-window {
  @media #{$mobile} {
    .btn,
    .cc-btn {
      position: absolute;
      bottom: 15px;
      margin: 0;
    }
    .btn {
      left: 15px
    }
    .cc-btn {
      left: 140px
    }
  }
}

.cc-compliance {
  a {
    min-width: 0 !important;
    padding: 0;
    border: 0;
    font-weight: normal;
    font-size: inherit;
  }
}

.cc-link {
  display: none !important;
}

.cc-banner .cc-message,
.cc-window .cc-message {
  flex: none;
  color: #807d78;
  font: 12px $font-1;
  @media #{$mobile} {
    font-size: 11px;
  }
}

.cc-window .cc-message {
  margin: 0;
}

.cc-window.cc-floating {
  @media #{$mobile} {
    position: relative;
    padding: 15px 15px 55px;
  }
}

/*** Page Desinscription ***/
.block-desinscription {
  min-height: 100vh;
  align-items: flex-end;
  padding-bottom: 24vh;
  @media (max-height: 768px) {
    padding-bottom: 17vh;
  }
  @media #{$mobile} {
    padding: 15px 15px 25px;
  }
  .block-form {
    display: flex;
    align-items: center;
    min-height: 210px;
    text-align: center;
    @media #{$mobile} {
      min-height: 0;
      padding: 25px 10px 30px;
    }
  }
  &:before,
  &:after {
    content: none;
  }
}

.desinscription-message {
  display: none;
}

.page-desinscription {
  .cc-grower,
  .cc-window {
    display: none !important;
  }
}