/*
* Third Party
*/
@import "partials/normalize.css";

/*
* Custom
*/
@import "partials/cookieconsent.min.css";
@import "partials/cookieconsent.min.css";
@import "partials/owl.carousel.min.css";
@import "partials/jquery.jscrollpane.css";
@import "partials/mixins";
@import "partials/app";

@import "partials/header";
@import "partials/content";
@import "partials/footer";